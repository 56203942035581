<template lang="pug">
    .login-wrapper.p-grid.p-align-center
        .p-col.p-grid.p-fluid.p-justify-center
            .form-wrapper.p-col-12.p-sm-6.p-md-5.p-lg-4.p-xl-3
                .ta-center

                    .img-logo-wrapper
                        object#medclub_svglogo(type="image/svg+xml" :data="require('./../../assets/img/logo-medclub-login.svg')")
                        img#medclub_imglogo(src='./../../assets/img/logo-medclub-login.png')

                vue-page-transition(name='fade-in-right')
                    router-view

                p.text-footer.ta-center.text-secondary MedClub &reg; 2020<br/>A7 Participações
</template>

<style lang="scss">
    .login-wrapper {
        .img-logo-wrapper {
            margin: 0 auto;
            margin-bottom: -20px;
            margin-top: 16px;
            max-width: 240px;
        }
        #medclub_svglogo,
        #medclub_imglogo {
            height: 40px;
            transition: .25s;
        }
        #medclub_imglogo {
            position:relative;
            top: -46px;
            opacity: 0;
            transition: .25s;
        }
    }
    body.login {
        overflow-y: auto !important;
    }
</style>

<script>
    import Vivus from 'vivus'
    
    export default {
        created () { localStorage.clear() },
        beforeDestroy () {
            document.body.classList.remove('login')
        },
        beforeCreate () {
            document.body.classList.add('login')
        },
        mounted () {
            new Vivus('medclub_svglogo', { duration: 50 }, () => {
                document.getElementById('medclub_imglogo').style.opacity = 1
                document.getElementById('medclub_svglogo').style.opacity = 0
            })
        }
    }
</script>